import {Checkbox, Input, Select} from "./input";

class InputGroup {
    inputs = [];
    inputsType = Input;

    title = '';
    subtitle = '';
    footnote = '';
    classInputGroup = '';

    constructor(...inputs) {
        this.inputs = [];

        if (Array.isArray(inputs)) {
            inputs.map((input) => {
                if (input instanceof this.inputsType) {
                    this.inputs[this.inputs.length] = input;
                } else {
                    console.error(input);
                    console.error('^---input NOT instanceof ' + this.inputsType);
                }
            })
        }
    }

    is() {
        return 'InputGroup';
    }

    setTitle(title) {
        this.title = title;
        return this;
    }

    setSubtitle(subtitle) {
        this.subtitle = subtitle;
        return this;
    }

    setFootnote(footnote) {
        this.footnote = footnote;
        return this;
    }
    setClass(classInputGroup) {
        this.classInputGroup = classInputGroup;
        return this;
    }
}

class CheckboxGroup extends InputGroup {
    inputsType = Checkbox;

    is() {
        return 'CheckboxGroup';
    }
}

class SelectGroup extends InputGroup {
    inputsType = Select;

    is() {
        return 'SelectGroup';
    }
}

export {InputGroup, CheckboxGroup, SelectGroup}