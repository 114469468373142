// import Validator from "./validator";

class Input {
    name = 'default';
    text = '';
    value = '';
    col = '';
    footnote = '';
    placeholder = '';
    #validatorOptions = {};

    constructor(name, text, value, col, footnote, placeholder, mask) {
        this.name = name || this.name;
        this.text = text || this.text;
        this.value = value || this.value;
        this.col = col || this.col;
        this.footnote = footnote || this.footnote;
        this.placeholder = placeholder || this.placeholder;
        this.mask = mask || this.mask;
    }

    is() {
        return 'Input';
    }

    setValidatorOptions(object) {
        this.#validatorOptions = object;
        return this;
    }

    getValidatorOptions() {
        return this.#validatorOptions;
    }
}

class Checkbox extends Input {
    constructor(name, text, value, col, footnote, placeholder, mask) {
        super(name, text, value, col, footnote, placeholder, mask);
    }

    is() {
        return 'Checkbox';
    }
}

class Select extends Input {
    options = [];

    constructor(name, text, value, col, footnote, options, placeholder, mask) {
        super(name, text, value, col, footnote, placeholder, mask);

        this.options = options;
        this.value = null;
    }

    is() {
        return 'Select';
    }
}

class InputFloatLabel extends Input {

    is() {
        return 'InputFloatLabel';
    }
}

export {Input, Checkbox, Select, InputFloatLabel};