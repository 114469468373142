import {InputGroup} from "./inputGroup";
import axios from "axios";

class Form {
    name = 'default';
    groups = [];
    errors = [];
    id = 0;

    constructor(name, inputGroups) {
        this.name = name;
        this.groups = [];
        this.errors = [];

        if (Array.isArray(inputGroups)) {
            inputGroups.map((inputGroup) => {
                if (inputGroup instanceof InputGroup) {
                    this.groups[this.groups.length] = inputGroup;
                } else {
                    console.error(inputGroup);
                    console.error('^---inputGroup NOT instanceof InputGroup');
                }
            });
        }

        this.id = this.randomString(5);
    }

    randomString(i) {
        let rnd = '';

        while (rnd.length < i) {
            rnd += Math.random().toString(36).substring(2);
        }

        return rnd.substring(0, i);
    }

    getValidatorParameters() {
        const ret = {};

        for (let i = 0; i < this.groups.length; i++) {
            const inputs = this.groups[i].inputs;
            for (let j = 0; j < inputs.length; j++) {
                const inputValidator = inputs[j].getValidatorOptions();

                if (Object.keys(inputValidator).length > 0) {
                    ret[inputs[j].name] = inputValidator;
                }
            }
        }

        return ret;
    }

    getFormData(componentData) {
        const formData = new FormData();

        for (let i = 0; i < this.groups.length; i++) {
            const inputs = this.groups[i].inputs;

            for (let j = 0; j < inputs.length; j++) {
                if (typeof componentData[inputs[j].name] !== 'undefined')
                    formData.append(inputs[j].name, componentData[inputs[j].name]);
            }
        }

        return formData;
    }

    getJson(componentData) {
        const object = {};

        for (let i = 0; i < this.groups.length; i++) {
            const inputs = this.groups[i].inputs;
            for (let j = 0; j < inputs.length; j++) {
                if (typeof componentData[inputs[j].name] !== 'undefined')
                    object[inputs[j].name] = componentData[inputs[j].name];
            }
        }

        return JSON.stringify(object);
    }

    async send(componentData) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/php/sendmail.php',
                mode: 'no-cors',
                data: this.getFormData(componentData)
            })
                .then((response) => {
                    if (response.data.mailSend) {
                        resolve(response.data);
                    } else {
                        this.errors = response.data.error;
                        reject(new Error('Смотри ошибки в Form.errors'));
                    }
                })
                .catch((error) => {
                    this.errors.push(error);
                    reject(error);
                });
        })
    }
}

export {Form};